import React, { useState } from 'react';
import { Nav,Navbar, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, NavLink } from 'reactstrap';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  
return (
<React.Fragment>

<Navbar style={{ display:"flex" }}  style={{ backgroundColor: "black",color:"white"}} dark expand="md">
<a className="font-weight-bold" href="https://blogue.tech"><img src="https://blogue.tech/static/images/logo.gif"  width="50" height="50" style={{ cursor: "pointer" }}/></a>
  <Nav className="ml-auto" navbar>
  <NavLink href="https://newshub.blogue.tech" style={{ cursor: "pointer" }}><b className="text-white">NewsHub</b></NavLink>
  <NavLink style={{ cursor: "pointer" }} href="https://blogue.tech/feeds"><b className="text-white">Feeds</b></NavLink>
    
  </Nav>
</Navbar>

<Navbar style={{ display:"flex" }}  color="dark" dark expand="md">
<a className="font-weight-bold" style={{ color:"white",cursor: "pointer" }} href="https://blogue.tech">
BLOGUE
</a>
<Nav className="ml-auto" navbar>

</Nav>
</Navbar>

</React.Fragment>
  );
};


export default Header;